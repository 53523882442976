<script setup>
import AuthLayout from "@/layouts/AuthLayout.vue";
import {router} from "@/helpers";
import { ref } from "vue";
import { fetchWrapper } from '@/helpers';
import {Form, Field} from 'vee-validate';
import * as Yup from 'yup';

const baseUrl = `${process.env.VUE_APP_API_URL}/fields`;
const id = router.currentRoute.value.params.id;
const schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  type: Yup.string().required('Type is required'),
});
const types = ['date','number','string','boolean'];

const formValues = ref( {
  name: '',
  type: '',
});
const success = ref( false);

const getField = async () => await fetchWrapper.get(`${baseUrl}/${id}`);
const updateField = async (params) => await fetchWrapper.put(`${baseUrl}/${id}/update`, params);
const storeField = async (params) => await fetchWrapper.post(`${baseUrl}/store`, params);
const destroyField = async () => await fetchWrapper.delete(`${baseUrl}/${id}/destroy`);
const handleSuccess = () => {
  success.value = true;
  setTimeout(() => success.value = false, 2000)
};
const onDelete = () => {
  if (confirm('Are you sure you want to delete?'))
    return destroyField()
        .then(() => router.push('/fields'))
}

const onSubmit = (values, {setErrors}) => {
  if (id) {
    return updateField(values)
        .then(() => handleSuccess())
        .catch(error => setErrors({apiError: error}));
  } else {
    return storeField(values)
        .then(() => {
          handleSuccess();
          router.push('/fields')
        })
        .catch(error => setErrors({apiError: error}));
  }
}

if (id) {
  getField()
      .then(({result}) => formValues.value = result)
      .catch(() => router.push('/fields'))
}

</script>

<template>
  <auth-layout>
    <div class="bg-gray-100 flex flex-col gap-2 rounded-md p-4">
      <Form :initial-values="formValues" :validation-schema="schema" @submit="onSubmit" v-slot="{ errors, isSubmitting }">
        <div class="-space-y-px rounded-md shadow-sm flex flex-col gap-2">
          <div>
            <Field name="title" placeholder="Title" type="text"
                   class="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                   :class="{ 'is-invalid': errors.title }"/>
            <div class="invalid-feedback">{{ errors.title }}</div>
          </div>
          <div>
            <Field name="type" as="select"
                   class="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                   :class="{ 'is-invalid': errors.type }">
              <option v-for="type in types" :key="type" :value="type">{{ type }}</option>
            </Field>
            <div class="invalid-feedback">{{ errors.type }}</div>
          </div>
        </div>

        <div v-if="success" class="my-4 p-4 bg-green-100 rounded-md">
          Success
        </div>

        <div v-if="errors.apiError" class="my-4 p-4 bg-red-100 rounded-md">{{errors.apiError}}</div>

        <div class="my-4 flex gap-2">
          <button :disabled="isSubmitting"
                  class="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            {{ id ? 'Update' : 'Create' }}
          </button>
          <button type="button"
                  v-if="id"
                  @click="onDelete()"
                  class="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            Delete
          </button>
        </div>

      </Form>
    </div>
  </auth-layout>
</template>