<script setup>
import { useAuthStore } from '@/stores/auth.js'
const mainStore = useAuthStore()
</script>

<template>
  <div>
    <div class="text-base">

      <header>
        <h1 class="py-4 text-center">
          <router-link to="/" class="text-xl font-bold text-gray-700 cursor-pointer">App Name</router-link>
        </h1>

        <nav class="mt-6">
          <ul class="flex justify-center  items-center space-x-4">
            <li><router-link to="/" class="text-md hover:font-semibold text-gray-600">Home</router-link></li>
            <li><router-link to="/profile" class="text-md hover:font-semibold text-gray-600">Profile</router-link></li>

            <template v-if="mainStore.user.role === 'admin'">
              <li><router-link to="/subscribers" class="text-md hover:font-semibold text-gray-600">Subscribers</router-link></li>
              <li><router-link to="/fields" class="text-md hover:font-semibold text-gray-600">Fields</router-link></li>
            </template>

            <li><a @click.prevent="mainStore.logout()" href="#" class="text-md hover:font-semibold text-gray-600">Logout</a></li>
          </ul>
        </nav>

      </header>

      <section class="body-font text-gray-600">
        <div class="container mx-auto px-5 py-10">
          <slot />
        </div>
      </section>
    </div>
  </div>
</template>
