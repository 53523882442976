<script setup>
import AuthLayout from "@/layouts/AuthLayout.vue";
import { ref } from "vue";
import { fetchWrapper } from '@/helpers';

const baseUrl = `${process.env.VUE_APP_API_URL}`;
const subscribers = ref( []);
const getSubscribers = async () => await fetchWrapper.get(`${baseUrl}/subscribers`);

getSubscribers().then(({result}) => subscribers.value = result);

</script>

<template>
  <auth-layout>
    <div class="flex flex-col gap-4">
      <div v-for="subscriber in subscribers" :key="subscriber.id" class="bg-gray-100 flex justify-between gap-2 rounded-md p-4">
        <div>
          {{ subscriber.name }}
        </div>

        <div>
          <router-link class="hover:font-bold" :to="{ name: 'subscriber-show', params: { id: subscriber.id }}">Edit</router-link>
        </div>
      </div>
    </div>
  </auth-layout>
</template>