import { createRouter, createWebHashHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth.js';

import Home from '@/views/HomeView.vue'
import Login from '@/views/LoginView.vue'
import Register from '@/views/RegisterView.vue'
import Profile from '@/views/ProfileView.vue'
import Subscribers from '@/views/SubscribersView.vue'
import Subscriber from '@/views/SubscriberView.vue'
import Fields from '@/views/FieldsView.vue'
import Field from '@/views/FieldView.vue'

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Home
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile
    },
    {
        path: '/subscribers',
        name: 'subscribers',
        component: Subscribers
    },
    {
        path: '/subscriber/:id',
        name: 'subscriber.show',
        component: Subscriber
    },
    {
        path: '/subscriber/:id',
        name: 'subscriber-show',
        component: Subscriber
    },
    {
        path: '/fields',
        name: 'fields',
        component: Fields
    },
    {
        path: '/fields-create',
        name: 'field-store',
        component: Field
    },
    {
        path: '/fields/:id',
        name: 'field-show',
        component: Field
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
]

export const router = createRouter({
    history: createWebHashHistory(process.env.VUE_APP_API_URL),
    linkActiveClass: 'active',
    routes,
    scrollBehavior (to, from, savedPosition) {
        return savedPosition || { top: 0 }
    }
})

router.beforeEach(async (to) => {
    const publicNames = ['login', 'register'];
    const authRequired = !publicNames.includes(to.name);
    const auth = useAuthStore();

    if (authRequired && !auth.user) {
        auth.returnUrl = to.fullPath;
        return '/login';
    } else if (auth.user && publicNames.includes(to.name)) {
        return '/';
    }

    const adminPages = [
        'subscribers',
        'subscriber-show',
        'fields',
        'field-show',
        'field-store',
    ];
    const canAdminRequired = adminPages.includes(to.name);
    if (canAdminRequired && auth.user.role !== 'admin')
        return '/';
});
