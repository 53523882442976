<script setup>
import AuthLayout from "@/layouts/AuthLayout.vue";
import {router} from "@/helpers";
import { ref } from "vue";
import { fetchWrapper } from '@/helpers';
import {Form, Field} from 'vee-validate';
import * as Yup from 'yup';

const baseUrl = `${process.env.VUE_APP_API_URL}`;
const id = router.currentRoute.value.params.id;
const schema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  name: Yup.string().required('Email is required'),
  state: Yup.string().required('State is required'),
});
const states = ['active','unsubscribed','junk','bounced','unconfirmed'];
const roles = ['subscriber','admin'];

const formValues = ref( null);
const success = ref( false);
const fields = ref( []);

const getSubscriber = async () => await fetchWrapper.get(`${baseUrl}/subscribers/${id}`);
const updateSubscriber = async (params) => await fetchWrapper.put(`${baseUrl}/subscribers/${id}/update`, params);
const destroySubscriber = async () => await fetchWrapper.delete(`${baseUrl}/subscribers/${id}/destroy`);
const getFields = async () => await fetchWrapper.get(`${baseUrl}/fields`);
const handleSuccess = () => {
  success.value = true;
  setTimeout(() => success.value = false, 2000)
};
const onDelete = () => {
  if (confirm('Are you sure you want to delete?'))
    return destroySubscriber()
        .then(() => router.push('/subscribers'))
}

const onSubmit = (values, {setErrors}) => {
  return updateSubscriber(values)
      .then(() => handleSuccess())
      .catch(error => setErrors({apiError: error}));
}
getFields().then(({result}) => fields.value = result);
getSubscriber()
    .then(({result}) => {
      let baseFields = (({ name, email, password, role, state }) => ({ name, email, password, role, state }))(result);
      formValues.value = {
        ...baseFields,
        fields: result.fields.map(field => field.id)
      }
    })
    .catch(() => router.push('/subscribers'))

</script>

<template>
  <auth-layout>
    <div class="bg-gray-100 flex flex-col gap-2 rounded-md p-4">
      <Form :initial-values="formValues" :validation-schema="schema" @submit="onSubmit" v-slot="{ errors, isSubmitting }">
        <div class="-space-y-px rounded-md shadow-sm flex flex-col gap-2">
          <div>
            <Field name="name" placeholder="Name" type="text"
                   class="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                   :class="{ 'is-invalid': errors.name }"/>
            <div class="invalid-feedback">{{ errors.name }}</div>
          </div>
          <div>
            <Field name="email" placeholder="Email address" type="text"
                   class="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                   :class="{ 'is-invalid': errors.email }"/>
            <div class="invalid-feedback">{{ errors.email }}</div>
          </div>
          <div>
            <Field name="state" as="select"
                   class="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                   :class="{ 'is-invalid': errors.state }">
              <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
            </Field>
            <div class="invalid-feedback">{{ errors.state }}</div>
          </div>
          <div>
            <Field name="role" as="select"
                   class="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                   :class="{ 'is-invalid': errors.role }">
              <option v-for="role in roles" :key="role" :value="role">{{ role }}</option>
            </Field>
            <div class="invalid-feedback">{{ errors.role }}</div>
          </div>
          <div>
            <Field name="password" placeholder="New password" type="password"
                   class="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                   :class="{ 'is-invalid': errors.password }"/>
            <div class="invalid-feedback">{{ errors.password }}</div>
          </div>

          <div v-for="field in fields" :key="field.id" class="flex gap-2 items-center">
            <Field name="fields" type="checkbox" :value="field.id" />
            <label>{{ field.title }} ({{ field.type }}) </label>
          </div>

        </div>

        <div v-if="success" class="my-4 p-4 bg-green-100 rounded-md">
          Success
        </div>

        <div v-if="errors.apiError" class="my-4 p-4 bg-red-100 rounded-md">{{errors.apiError}}</div>

        <div class="my-4 flex gap-2">
          <button :disabled="isSubmitting"
                  class="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            Update
          </button>
          <button type="button"
                  @click="onDelete()"
                  class="group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            Delete
          </button>
        </div>

      </Form>
    </div>
  </auth-layout>
</template>