<script setup>
import AuthLayout from "@/layouts/AuthLayout.vue";
import { ref } from "vue";
import { fetchWrapper } from '@/helpers';

const baseUrl = `${process.env.VUE_APP_API_URL}`;
const fields = ref( []);
const getFields = async () => await fetchWrapper.get(`${baseUrl}/fields`);

getFields().then(({result}) => fields.value = result);

</script>

<template>
  <auth-layout>

    <div class="text-right">
      <router-link class="hover:font-bold" to="/fields-create">
        Create a new field
      </router-link>
    </div>

    <div class="flex flex-col gap-4">
      <div v-for="field in fields" :key="field.id" class="bg-gray-100 flex justify-between gap-2 rounded-md p-4">
        <div>
          {{ field.title }} - {{ field.type }}
        </div>

        <div>
          <router-link class="hover:font-bold" :to="{ name: 'field-show', params: { id: field.id }}">Edit</router-link>
        </div>
      </div>
    </div>
  </auth-layout>
</template>