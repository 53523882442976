<script setup>
import GuestLayout from "@/layouts/GuestLayout.vue";
import {Form, Field} from 'vee-validate';
import * as Yup from 'yup';
import {fetchWrapper} from "@/helpers";
import {router} from "@/helpers";

const baseUrl = `${process.env.VUE_APP_API_URL}/auth/register`;
const schema = Yup.object().shape({
  name: Yup.string().required('Email is required'),
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required')
});

const storeProfile = async (params) => await fetchWrapper.post(baseUrl, params);

const onSubmit = (values, {setErrors}) => {
  return storeProfile(values)
      .then(() => router.push('/login'))
      .catch(error => setErrors({apiError: error}));
}

</script>

<template>
  <guest-layout>
    <div class="w-full max-w-md space-y-8">
      <div>
        <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
             alt="Your Company">
        <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Sign up to your account</h2>
      </div>
      <Form class="mt-8 space-y-6" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">

        <div class="-space-y-px rounded-md shadow-sm">
          <div>
            <Field name="name" placeholder="Name" type="text"
                   class="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                   :class="{ 'is-invalid': errors.name }"/>
            <div class="invalid-feedback">{{ errors.name }}</div>
          </div>
          <div>
            <Field name="email" placeholder="Email address" type="text"
                   class="relative block w-full appearance-none rounded-none rounded-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                   :class="{ 'is-invalid': errors.email }"/>
            <div class="invalid-feedback">{{ errors.email }}</div>
          </div>
          <div>
            <Field name="password" placeholder="Password" type="password"
                   class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                   :class="{ 'is-invalid': errors.password }"/>
            <div class="invalid-feedback">{{ errors.password }}</div>
          </div>
        </div>

        <div>
          <button :disabled="isSubmitting"
                  class="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
            Sign up
          </button>
        </div>

        <div class="text-right">
          <router-link class="hover:font-bold" to="/login">
            Already have an account?
          </router-link>
        </div>

        <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{errors.apiError}}</div>

      </Form>
    </div>
  </guest-layout>
</template>