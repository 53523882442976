<script setup>
import AuthLayout from "@/layouts/AuthLayout.vue";
import { useAuthStore } from '@/stores/auth.js'
const mainStore = useAuthStore()

</script>

<template>
  <auth-layout>
    <div class="text-center font-bold">
      {{ mainStore.user.name }}, hello!
    </div>
  </auth-layout>
</template>