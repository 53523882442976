<script setup>
import AuthLayout from "@/layouts/AuthLayout.vue";
import { ref } from "vue";
import { fetchWrapper } from '@/helpers';
import {Form, Field} from 'vee-validate';

const baseUrl = `${process.env.VUE_APP_API_URL}`;

const profile = ref( null);
const success = ref( false);
const fields = ref( []);
const formValues = ref( {
  fields: [],
});

const getProfile = async () => await fetchWrapper.get(`${baseUrl}/profile`);
const updateProfile = async (params) => await fetchWrapper.put(`${baseUrl}/profile/update`, params);
const getFields = async () => await fetchWrapper.get(`${baseUrl}/fields`);
const handleSuccess = () => {
  success.value = true;
  setTimeout(() => success.value = false, 2000)
};
const handelGetProfile = () => {
  getProfile().then(({result}) => {
    profile.value = result;
    formValues.value.fields = result.fields.map(field => field.id);
  })
};
getFields().then(({result}) => fields.value = result);
handelGetProfile();
const onSubmit = (values, {setErrors}) => {
  return updateProfile(values)
      .then(() => {
        handleSuccess();
        handelGetProfile();
      })
      .catch(error => setErrors({apiError: error}))
}
const getStatusSubscribe = (fieldId) => {
  if(formValues.value.fields.includes(fieldId))
    return 'Subscribed';
  if(profile.value.history_fields.some((field) => field.id === fieldId))
    return 'Unsubscribed';
}


</script>

<template>
  <auth-layout>
    <div v-if="profile" class="bg-gray-100 flex flex-col gap-2 rounded-md p-4">
      <div>Name: <span class="font-bold">{{ profile.name }}</span></div>
      <div>Email: <span class="font-bold">{{ profile.email }}</span></div>
      <div>Role: <span class="font-bold">{{ profile.role }}</span></div>
      <div>State: <span class="font-bold">{{ profile.state }}</span></div>
      <div>

        <Form :initial-values="formValues" @submit="onSubmit" v-slot="{ isSubmitting }">

          <div v-for="field in fields" :key="field.id" class="flex gap-2 items-center">
            <Field name="fields" type="checkbox" :value="field.id" />
            <label>{{ field.title }} ({{ field.type }}) <span class="font-bold">{{getStatusSubscribe(field.id)}}</span> </label>
          </div>

          <div v-if="success" class="my-4 p-4 bg-green-100 rounded-md">
            Success
          </div>

          <div class="my-4">
            <button :disabled="isSubmitting"
                    class="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              Update
            </button>
          </div>

        </Form>
      </div>
    </div>
  </auth-layout>
</template>
